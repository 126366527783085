import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { graphql } from "gatsby";
import { useLazyQuery } from "@apollo/react-hooks";

import { ContentBlock, TextBlock } from "../../components/Blocks";
import Pages from "../../components/Pages";
import { ContentList } from "../../components/Lists";
import Breadcrumbs from "../../components/Layout/Breadcrumbs";
import seoData from "../../components/SEO/data";
import { formatURL, removeEmptyKeys, removePreloader } from "../../helpers";
import { Share } from "../../widgets";
import { Button } from "antd";
import NoScript from "../../components/NoScript";
import { VIRTUAL_TOUR_LIST } from "../../queries/queries.graphql";
import { showMore } from "../../constants";
import { useThemeContext } from "../../components/Layout/ThemeContext";

export const query = graphql`
	query VirtualToursList($pagePath: String!)  {
		hasura {
			...VirtualToursPage
			...PageSettings
		}
	}
`;

export default function VirtualToursList({ data }) {
	const { theme } = useThemeContext();

	function makeData(data = [], type) {
		return data.map(({ page_title_short, page_title_full, slug, main_image_preview, main_image }) => {
			const image = get(main_image_preview, "src", "") || get(main_image, "src", "");

			return {
				slug: formatURL(`media/${type}/${slug}`),
				image,
				title: page_title_short || page_title_full,
			};
		});
	}

	const url = "virtual_tours";
	const limit = 4;
	const [virtualTours, setVirtualTours] = useState(makeData(get(data, "hasura.virtual_tours", []), "3d-tours"));

	const [offset, setOffset] = useState(limit);
	const seo = get(data, "hasura.page_settings[0]", {});
	const isAllToursLoaded = get(data, "hasura.virtual_tours_aggregate.aggregate.count", 0) === virtualTours.length;

	const [loadVirtualTours, { called, loading, error, data: virtualToursData }] = useLazyQuery(
		VIRTUAL_TOUR_LIST,
		{
			variables: {
				limit,
			},
			fetchPolicy: "cache-and-network",
			partialRefetch: true,
		});

	useEffect(() => {
		if (called && !error && !loading) {
			setVirtualTours([...virtualTours, ...makeData(get(virtualToursData, "virtual_tours", []), "3d-tours")]);
			setOffset(get(virtualToursData, "radio", []).length < limit ? null : offset + limit);
		} else if (error) {
			setOffset(null);
		}

		removePreloader(!loading || error);
	}, [loading]);

	const entity = get(seoData, "virtual_tours", {});

	return (
		<Pages entity={seo || entity} url={url}>
			<ContentBlock key={"breadcrumbs"}>
				<div className="container">
					<Breadcrumbs currentLabel={"Виртуальные экскурсии"} />
				</div>
			</ContentBlock>
			<ContentBlock key={"main-content"}>
				<div className={"container"}>
					<TextBlock title={"Виртуальные экскурсии"}>
						<ContentList
							items={virtualTours}
							className={"col-12 col-md-6 d-noscript-none"}
							style={{
								width: "100%",
								height: "400px",
								objectFit: "cover",
							}}
						/>
						<NoScript>
							<ContentList
								items={virtualTours}
								className={"col-12 col-md-6"}
								style={{
									width: "100%",
									height: "400px",
									objectFit: "cover",
								}}
							/>
						</NoScript>
					</TextBlock>
					<div className="row justify-content-center mb-5 d-noscript-none">
						<Button
							disabled={loading || !offset || isAllToursLoaded}
							loading={loading}
							onClick={() => loadVirtualTours({
								variables: {
									offset,
								},
							})}
							style={removeEmptyKeys({
								fontFamily: theme.fontFamily,
								fontSize: theme.fontSize.text,
								lineHeight: theme.lineHeight.text,
								color: theme.color.body,
								backgroundColor: theme.color.text,
							})}
						>
							{showMore}
						</Button>
					</div>
				</div>
			</ContentBlock>
			<div className={"container"}>
				<Share url={url} pageTitleShort={"Виртуальные экскурсии"} />
			</div>
		</Pages>
	);
}

VirtualToursList.propTypes = {
	data: PropTypes.object,
};

VirtualToursList.defaultProps = {
	data: {},
};

